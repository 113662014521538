(function($) {
  // GLOBAL VARS

  var map = null;
  var activeID = null;
  var currentMarker;
  var directionsService;
  var directionsDisplay;
  var isLarge;
  var $markers;

  var tour = false;
  var onCategory = false;

  var activeMarkers = [];
	var allMarkers = [];

	var SVG_hexagon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 18" enable-background="new 0 0 20.8 18"><path d="M5.2 0l-5.2 9 5.2 9h10.4l5.2-9-5.2-9z"/></svg>';
	var SVG_hexapin = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 26.6" enable-background="new 0 0 20.8 26.6"><path d="M20.8 9l-5.2-9h-10.4l-5.2 9 10.4 17.6z"/></svg>';

  // GLOBAL OPTIONS

  function DirectionsArgs(map) {
    return {
      suppressMarkers: true,
      suppressInfoWindows: true,
      map: map,
      polylineOptions: {
        strokeColor: "#000000",
        strokeWeight: 2
      }
    };
  }

  var owlOptions = {
    loop: false,
    nav: true,
    center: true,
    navText: [
      '<img src="' +
        SiteParameters.theme_base +
        '/assets/images/arrow.svg" />',
      '<img src="' +
				SiteParameters.theme_base +
        '/assets/images/arrow.svg" />'
    ],
    responsive: {
      0: {
        items: 1
      },
      800: {
        items: 2
      },
      1024: {
        items: 3
      }
    }
  };

  // GOOGLE MAPS FUNCTIONS

  function NewMap($el) {
    var args = {
      zoom: 16,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    map = new google.maps.Map($el[0], args);
    map.markers = [];

    if (!tour) {
      $markers.each(function(i) {
        AddMarker($(this), map, i);
      });
    }

    map.set("styles", [
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [{ color: "#d3d3d3" }]
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#696969" }]
      },
      {
        featureType: "transit",
        stylers: [{ color: "#808080" }, { visibility: "off" }]
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ visibility: "on" }, { visibility: "off" }]
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [{ color: "#ffffff" }]
      },
      {
        featureType: "road.local",
        elementType: "geometry.fill",
        stylers: [{ visibility: "on" }, { color: "#ffffff" }, { weight: 1.8 }]
      },
      {
        featureType: "road.local",
        elementType: "geometry.stroke",
        stylers: [{ color: "#d7d7d7" }]
      },
      {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [{ visibility: "on" }, { color: "#ebebeb" }]
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [{ color: "#a7a7a7" }]
      },
      {
        featureType: "road.arterial",
        elementType: "geometry.fill",
        stylers: [{ color: "#ffffff" }]
      },
      {
        featureType: "road.arterial",
        elementType: "geometry.fill",
        stylers: [{ color: "#ffffff" }]
      },
      {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [{ visibility: "on" }, { color: "#efefef" }]
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#696969" }]
      },
      {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [{ visibility: "on" }, { color: "#737373" }]
      },
      {
        featureType: "poi",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "road.arterial",
        elementType: "geometry.stroke",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }]
      },
      {},
      {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [{ color: "#dadada" }]
      }
    ]);

    if (tour) {
      GetTourMarkers(map);
    } else {
      if (activeID !== null) {
        CenterSingle(map);
      } else {
        CenterMap(map);
      }
    }

    return map;
  }

  function GeoLocator() {
    var startPos;
    var geoOptions = {
      timeout: 10 * 1000
    };

    var geoSuccess = function(position) {
      startPos = position;
      document.getElementById("startLat").innerHTML = startPos.coords.latitude;
      document.getElementById("startLon").innerHTML = startPos.coords.longitude;
    };
    var geoError = function(error) {
      console.log("Error occurred. Error code: " + error.code);
      // error.code can be:
      //   0: unknown error
      //   1: permission denied
      //   2: position unavailable (error response from location provider)
      //   3: timed out
    };

    navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions);
  }

  function CalculateAndDisplayRoute(
    directionsDisplay,
    directionsService,
    markerArray
  ) {
    var waypts = [];

    for (var i = 1; i < markerArray.length - 1; i++) {
      waypts.push({
        location: markerArray[i].position,
        stopover: true
      });
    }

    directionsService.route(
      {
        origin: markerArray[0].position,
        destination: markerArray[markerArray.length - 1].position,
        waypoints: waypts,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.WALKING
      },
      function(response, status) {
        if (status === google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(response);
          var route = response.routes[0];
        } else {
          console.log("Directions request failed due to " + status);
        }
      }
    );
  }

  function AddMarker($marker, map, i) {
    // var
    var iconBase = SiteParameters.theme_base + "/assets/images/";
    var icon = new google.maps.MarkerImage(
      iconBase + "marker.png",
      null,
      null,
      null,
      new google.maps.Size(0, 0)
    );

    var latlng = new google.maps.LatLng(
      $marker.attr("data-lat"),
      $marker.attr("data-lng")
    );
    var category = $marker.attr("data-category");
    var title = $marker.attr("data-title");
    var id = $marker.attr("data-id");
    var href = $marker.attr("data-href");
    var content = "";
    var classes = [];
    var classesString = "";
    var classesInitial = [];

    classesInitial.push(
      "markerLabels",
      "hexagon",
      "map-marker",
      "marker-category-" + category,
      "color-" + category,
      "marker-" + i,
      "object-id-" + id
    );

    for (var n = 0; n < classesInitial.length; n++) {
      classes.push(classesInitial[n]);
    }

    if (activeID !== null && activeID == id) {
      classes.push("current-marker");
    }

		content += '<div data-id="' + id + '">';
		content += SVG_hexapin;
    content +=
      '<div class="hover-content"><div><p>' + title + "</p></div></div>";
    content += "</div>";

    var anchor = new google.maps.Point(15, 30);

    for (var m = 0; m < classes.length; m++) {
      classesString += classes[m] + " ";
    }

    // create marker
    var marker = new MarkerWithLabel({
      position: latlng,
      labelContent: content,
      map: map,
      icon: icon,
      labelAnchor: anchor,
      labelClass: classesString
    });

    // add to array
    map.markers.push(marker);

    google.maps.event.addListener(marker, "mouseover", function() {
      classes = [];
      for (var g = 0; g < classesInitial.length; g++) {
        classes.push(classesInitial[g]);
      }
      classes.push("hover");
      classesString = "";
      for (var r = 0; r < classes.length; r++) {
        classesString += classes[r] + " ";
      }

      marker.set("labelClass", classesString);
    });

    google.maps.event.addListener(marker, "mouseout", function() {
      classes = [];
      for (var t = 0; t < classesInitial.length; t++) {
        classes.push(classesInitial[t]);
      }
      if (activeID !== null && activeID == id) {
        classes.push("current-marker");
      }
      classesString = "";
      for (var s = 0; s < classes.length; s++) {
        classesString += classes[s] + " ";
      }

      marker.set("labelClass", classesString);
    });

    if ($marker.html()) {
      // create info window
      var infowindow = new google.maps.InfoWindow({
        content: title
      });
    }

    google.maps.event.addListener(marker, "click", function() {
      var labelContent = $(this)[0].labelContent;
      var labelId = $(labelContent)[0].dataset.id;

      if (labelId !== "undefined") {
        tour = false;
        activeMarkers = allMarkers;
        activeID = parseInt(labelId);

        $("#ajax-content").addClass("fade-out");
        $("body").removeClass("toggle-map");
        $("body").removeClass("single-tours");

        $(".ajax-inner").one(
          "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
          function(e) {
            tour = false;
            $(this).remove();
            new SingePlace(id, href);
          }
        );
      }
    });
  }

  function GetTourMarkers(map) {
    directionsService = new google.maps.DirectionsService();
    directionsDisplay = new google.maps.DirectionsRenderer(DirectionsArgs(map));

    ResetMarkers();

    var $tour_markers = $("body").find(".tour-marker");
    $tour_markers.each(function(i) {
      activeMarkers.push($(this).data("id"));

      AddMarker($(this), map, i);
    });

    CalculateAndDisplayRoute(directionsDisplay, directionsService, map.markers);
    google.maps.event.addListenerOnce(map, "idle", function() {
      CenterTour(map, 0);
    });
  }

  function CenterMap(map) {
    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function(i, marker) {
      var latlng = new google.maps.LatLng(
        marker.position.lat(),
        marker.position.lng()
      );

      bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length == 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
      map.setZoom(16);
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }
  }

  function CenterSingle(map) {
    // vars
    var bounds = new google.maps.LatLngBounds();
    var latlng = new google.maps.LatLng(
      map.markers[currentMarker].position.lat(),
      map.markers[currentMarker].position.lng()
    );
    bounds.extend(latlng);

    map.setCenter(bounds.getCenter());
    map.setZoom(14);
  }

  var lastCount;

  function CenterTour(map, count) {
    var bounds = new google.maps.LatLngBounds();
    var latlng = new google.maps.LatLng(
      map.markers[count].position.lat(),
      map.markers[count].position.lng()
    );

    bounds.extend(latlng);

    map.fitBounds(bounds);
    map.setZoom(16);
    //map.panBy(1250, 0);

    if (lastCount >= 0) {
      var lastTourClasses = map.markers[lastCount].labelClass;
      lastTourClasses = lastTourClasses.split(" ");
      var currentMarkerI = lastTourClasses.indexOf("current-marker");

      if (currentMarkerI != -1) {
        lastTourClasses.splice(currentMarkerI, 1);
      }

      lastTourClasses = lastTourClasses.join(" ");
      map.markers[lastCount].labelClass = lastTourClasses;
    }

    tourClasses = map.markers[count].labelClass;
    tourClasses = tourClasses.split(" ");
    tourClasses.push("current-marker");
    tourClasses = tourClasses.join(" ");

    map.markers[count].labelClass = tourClasses;

    lastCount = count;
  }

  function ReloadMarkers() {
    var $markers = $("body").find(".marker-active");

    ResetMarkers();

    $markers.each(function(i) {
      if ($.inArray($(this).data("id"), activeMarkers) != -1) {
        if ($(this).data("id") == activeID) {
          currentMarker = i;
        }
        AddMarker($(this), map, i);
      }
    });

    if (activeID !== null) {
      CenterSingle(map);
    } else {
      CenterMap(map);
    }

    if (!tour && directionsDisplay) {
      directionsDisplay.setMap(null);
    }

    google.maps.event.trigger(map, "resize");
  }

  function ResetMarkers() {
    // Loop through markers and set map to null for each
    for (var i = 0; i < map.markers.length; i++) {
      map.markers[i].setMap(null);
    }

    // Reset the markers array
    map.markers = [];
  }

  // AJAX FUNCTIONS

  var content = $("#ajax-content");

  function PreventD(e) {
    if (!console.error) {
      e.preventDefault();
    }
  }

  function ChangeBrowserURL(content, href) {
    var content_object = {};
    if (content !== null) {
      content_object = { content: content };
    } else {
      content_object = null;
    }

    if (history.pushState) {
      history.pushState(content_object, null, href);
    } else {
      document.location.hash = href;
    }
  }

  function GetOptimalGalleryImage (imageObj) {
    if (imageObj.sizes.large) {
      return imageObj.sizes.large;
    } else if (imageObj.sizes.medium) {
      return imageObj.sizes.medium;
    } else {
      return imageObj.url;
    }
  }

  function SingePlace(id, href) {
    if ($("body").hasClass("lang-et")) {
      url = SiteParameters.base + "/wp-json/wp/v2/places/" + id + "?lang=et";
    } else {
      url = SiteParameters.base + "/wp-json/wp/v2/places/" + id;
    }

    $.ajax({
      url: url,
      type: "GET",
      dataType: "json",
      complete: function(d) {
        var data = $.parseJSON(d.responseText);
        var title = data.title.rendered,
          content = data.content.rendered,
          categories = data.term_name,
          original_term = data.term_translation,
          address = data.acf.address,
          opening_times = data.acf.opening_times,
          url = data.acf.url,
          gallery = data.acf.gallery,
          thumbnail,
          item;

        if (data.better_featured_image) {
          if (data.better_featured_image.media_details) {
            if (
              data.better_featured_image.media_details.sizes.large !== undefined
            ) {
              thumbnail =
                data.better_featured_image.media_details.sizes.large.source_url;
            } else if (
              data.better_featured_image.media_details.sizes.medium !==
              undefined
            ) {
              thumbnail =
                data.better_featured_image.media_details.sizes.medium
                  .source_url;
            } else {
              thumbnail =
                data.better_featured_image.media_details.sizes.thumbnail
                  .source_url;
            }
          }
        }

        item = '<div class="ajax-inner">';
        item += '<article class="single-article">';
        item += '<header class="block">';
        item += '<h1 class="entry-title">' + title + "</h1>";
        item += "<ul>";
        item += "<li>";
        for (var i = 0; i < categories.length; i++) {
					item += '<span>';
          item +=
						'<div class="category-icon color-' + original_term[i] + '">' +
						SVG_hexagon +
						'</div>' +
						categories[i] +
						"<br>";
					item += '</span>';
        }
        item += "</li>";
        if (address) {
          item += "<li>" + address + "</li>";
        }
        if (opening_times) {
          item += "<li>" + opening_times + "</li>";
        }
        if (url) {
          item += '<li><a href="http://' + url + '">' + url + "</a></li>";
        }
        item += "</ul>";
        item += '<div class="clear"></div>';
        item += "</header>";
        item += '<div class="entry-content block collapse-top">';
        item += '<div class="content-block">';
        item += content;
        item += "</div>";
        if (thumbnail) {
          item += "<figure>";
          item += '<img src="' + thumbnail + '" alt="' + data.better_featured_image.alt_text + '" />';
          item += "</figure>";
        }

        if (gallery) {
          for (var m = 0; m < gallery.length; m++) {
            var image = gallery[m];
            var optimalImage = GetOptimalGalleryImage(image);

            item += "<figure>";
              item += '<img data-action="zoom" src="' + optimalImage + '" alt="' + image.alt + '" />';
            item += "</figure>";
          }
        }

        item += "</div>";
        item += "</article>";
        item += "</div>";

        ChangeBrowserURL(item, href);
        $("#ajax-content").html(item);

        google.maps.event.trigger(map, "resize");
        new ReloadMarkers();
      }
    });
  }

  function AjaxPage(href, tour) {
    $.get(href, function(data) {
      data = $(data).find("#ajax-content");
      data_html = data[0].innerHTML;
      $("#ajax-content").html(data_html);
      ChangeBrowserURL(data_html, href);
    }).done(function(data) {
      new ReloadMarkers();
      if (tour) {
        google.maps.event.trigger(map, "resize");
        google.maps.event.addListenerOnce(map, "tilesloaded", function() {
          CenterTour(map, 0);
        });
        new OwlSlider();
      }
    });
  }

  function AjaxNavigation() {
    $(document).on("click", ".list-item > h2 > a", function(e) {
      tour = false;
      if (isLarge) {
        e.preventDefault();

        $("body").removeClass("toggle-map");
        activeMarkers = allMarkers;
        new ReloadMarkers();
        var $this = $(this);
        var id = $(this).data("id");
        activeID = parseInt(id);

        $("#ajax-content").addClass("fade-out");
        $(".ajax-inner").one(
          "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
          function(e) {
            $(this).remove();
            new SingePlace(id, $this.attr("href"));
          }
        );
      }
    });

    $(document).on("click", ".tour-item > a", function(e) {
      if (isLarge) {
        e.preventDefault();
        var $this = $(this);
        tour = true;

        $("body").addClass("single-tours");

        $("#ajax-content").addClass("fade-out");
        $(".ajax-inner").one(
          "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
          function(e) {
            $(this).remove();
            new AjaxPage($this.attr("href"), true);
          }
        );
      }
    });

    $(document).on("click", ".js-ajax-nav a", function(e) {
      tour = false;
      if (isLarge) {
        e.preventDefault();
        currentMarker = null;
        var $this = $(this);
        activeID = null;

        activeMarkers = allMarkers;
        new ReloadMarkers();

        $("body").removeClass("toggle-tour");
        if ($this.attr("id") !== "menu-item-45") {
          $("body").removeClass("single-tours");
        }

        $("#ajax-content").addClass("fade-out");
        $(".ajax-inner").one(
          "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
          function(e) {
            $(this).remove();
            new AjaxPage($this.attr("href"), false);
          }
        );
      }
    });
  }

  window.onpopstate = function(event) {
    if (event.state !== null) {
      $("#ajax-content").html(event.state.content);
      currentMarker = null;
      new reloadMarkers();
    } else {
      AjaxPage(document.location);
    }
  };

  $(document).on("hover", ".list-item a", function(e) {
    if (isLarge) {
      var id = $(this).data("id");
      $(".object-id-" + id).toggleClass("hover");
    }
  });

  $(document).on("click", "a.js-toggle-category", function(e) {
    e.preventDefault();

    var currentCat = $(this).data("category");

    activeMarkers = [];

    if (
      $(this)
        .parent()
        .parent()
        .hasClass("active")
    ) {
      onCategory = false;
      $(".category").removeClass("active");
      activeMarkers = allMarkers;
      if (isLarge) {
        new ReloadMarkers();
      }
    } else {
      onCategory = true;
      $(".category").removeClass("active");
      $(this)
        .parent()
        .parent()
        .toggleClass("active");

      var $markers = $("body").find(".marker");

      for (var i = 0; i < $markers.length; i++) {
        if ($($markers[i]).hasClass("category__" + currentCat)) {
          markerID = $($markers[i]).data("id");
          activeMarkers.push(markerID);
        }
        if (i == $markers.length - 1) {
        }
      }
      if (isLarge) {
        new ReloadMarkers();
      }
    }
  });

  function OwlSlider() {
    var owl = $(".owl-carousel");
    owl.owlCarousel(owlOptions);
    owl.on("changed.owl.carousel", function(event) {
      CenterTour(map, event.item.index);
    });
  }

  function BodyToggle() {
    $("a.js-toggle").click(function(e) {
      e.preventDefault();
      var toggle = $(this).attr("href");
      $("body").toggleClass(toggle);
      if (!isLarge) {
        if (onCategory) {
          new ReloadMarkers();
        }
      }
    });
  }

  $(document).ready(function() {
    new BodyToggle();
    new AjaxNavigation();

    if ($("body").hasClass("single")) {
			currentPostID = $("body").attr("class").split(" ");
			var idPosition = currentPostID.reIndexOf(/postid/);
      currentPostID = currentPostID[idPosition].substring(7);
			activeID = parseInt(currentPostID);
    }

    if ($("body").hasClass("single-tours")) {
      tour = true;
    }

		$markers = $("body").find(".marker");

    $markers.each(function(i) {
      if ($(this).data("id") === activeID) {
        currentMarker = i;
      }

      allMarkers.push($(this).data("id"));
      activeMarkers.push($(this).data("id"));
    });

    $(".acf-map").each(function() {
      map = NewMap($(this));
    });

    if (Foundation.MediaQuery.atLeast("medium")) {
      isLarge = true;
    } else {
      isLarge = false;
    }
  });

  $(window).load(function() {
    new OwlSlider();
  });

  $(window).resize(function() {
    google.maps.event.trigger(map, "resize");
    CenterMap(map);

    if (Foundation.MediaQuery.atLeast("medium")) {
      isLarge = true;
    } else {
      isLarge = false;
    }
  });

  $(document)
    .ajaxStart(function() {
      NProgress.start();
    })
    .ajaxSuccess(function() {
      NProgress.done();
    });

  $(document).ajaxComplete(function(event, request, settings) {
    $("#ajax-content").removeClass("fade-out");
    if (tour) {
      new GetTourMarkers(map);
    }
  });
})(jQuery);
